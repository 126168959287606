<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id">
                            <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            >
                            <template v-slot:label>
                            {{$t('warehouse_config.fiscal_year')}}
                            </template>
                            <b-form-select
                            plain
                            v-model="search.fiscal_year_id"
                            :options="fiscalList"
                            id="fiscal_year_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col  md="5">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('seedsSeeds.dealer_name')"
                            label-for="email"
                        >
                            <v-select name="croptypeID"
                                v-model="search.dealer_name"
                                label="text"
                                :options= dealerList
                            />
                        </b-form-group>
                    </b-col>
                    <b-col >
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('seedsSeeds.dealer_wise_allocation')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                             {{  $t('globalTrans.add_new') }}
                        </b-button> -->
                        <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered striped hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                        <!-- <b-button v-b-modal.modal-5 variant=" iq-bg-warning mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="fas fa-eye"></i></b-button> -->
                                        <a href="javascript:" v-b-modal.modal-5 @click="edit(data.item)" class="btn_table_action table_action_view" title="Status" size="sm">
                                            <i class="fas fa-eye"></i>
                                        </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="this.$t('globalTrans.view')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :id="editItemId" :key="editItemId" ref="details"/>
            <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Details from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { dealerWiseAllocationList, officeWiseAllocationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form,
        Details
    },
    data () {
        return {
            search: {
                fiscal_year_id: 0,
                off_dealer_farmer_comp_id: 0,
                org_id: this.$store.state.dataFilters.orgId,
                to_sales_office_id: this.$store.state.dataFilters.officeId
            }
        }
    },
    computed: {
        orgList: function () {
            return this.$store.state.orgList.filter(item => item.status === 0)
        },
        fiscalList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            return fiscalyearData.map(item => {
                if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
                } else {
                return { value: item.value, text: item.text_en }
                }
            })
        },
        productionSeasonList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
        },
        CropTypeList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('seedsSeeds.dealer_wise_allocation') + ' ' + this.$t('globalTrans.entry') : this.$t('seedsSeeds.dealer_wise_allocation') + ' ' + this.$t('globalTrans.update')
        },
        dealerList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.dealerBasicList.filter(item => item.status === 1)
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('warehouse_config.fiscal_year'), class: 'text-left' },
                { label: this.$t('seedsConfig.productionSeason'), class: 'text-left' },
                { label: this.$t('seedsSeeds.dealer_name'), class: 'text-left' },
                { label: this.$t('seedsSeeds.seedsClass'), class: 'text-left' },
                { label: this.$t('seedsSeeds.office_type'), class: 'text-left' },
                { label: this.$t('seedsSeeds.office'), class: 'text-left' },
                { label: this.$t('seedsConfig.cropType'), class: 'text-left' },
                { label: this.$t('seedsConfig.cropName'), class: 'text-left' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'fiscal_year_bn' },
                    { key: 'production_season_name_bn' },
                    { key: 'dealer_name_bn' },
                    { key: 'seed_class_bn' },
                    { key: 'office_type_bn' },
                    { key: 'zoneOffice_name_bn' },
                    { key: 'crop_type_name_bn' },
                    { key: 'crop_name_name_bn' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'fiscal_year' },
                    { key: 'production_season_name' },
                    { key: 'dealer_name' },
                    { key: 'seed_class' },
                    { key: 'office_type' },
                    { key: 'zoneOffice_name' },
                    { key: 'crop_type_name' },
                    { key: 'crop_name_name' },
                    { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        currentFiscalYearId: function () {
            return this.$store.state.SeedsFertilizer.currentFiscalYearId
        }
    },
    async created () {
        this.search = Object.assign({}, this.search, { fiscal_year_id: this.currentFiscalYearId })
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, officeWiseAllocationToggleStatus, item, 'seeds_fertilizer', 'godownInfoList')
        },
        loadData () {
            if (this.search.fiscal_name) {
                this.search.fiscal_year_id = this.search.fiscal_name.value
            }
            if (this.search.dealer_name) {
                this.search.off_dealer_farmer_comp_id = this.search.dealer_name.value
            }
            const params = Object.assign({}, this.search, {page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, dealerWiseAllocationList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelationalData(response.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (data) {
            const productionSeasonList = this.$store.state.SeedsFertilizer.commonObj.productionSeasonList
            const cropTypeListList = this.$store.state.SeedsFertilizer.commonObj.CropTypeList
            const cropNameListList = this.$store.state.SeedsFertilizer.commonObj.CropNameList
            const seedClassList = this.$store.state.SeedsFertilizer.commonObj.seedClassList
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList
            const organizationList = this.$store.state.orgList
            const spcOfficeList = this.$store.state.SeedsFertilizer.commonObj.spcOfficeList
            const zoneOfficeList = this.$store.state.commonObj.officeList
            const carryoverList = this.$store.state.SeedsFertilizer.commonObj.carryoverList
            const measurementUnitList = this.$store.state.SeedsFertilizer.commonObj.unitList
            const varietyList = this.$store.state.SeedsFertilizer.commonObj.VarietyList
            const dealerList = this.$store.state.SeedsFertilizer.commonObj.dealerBasicList
            const officeTypeList = this.$store.state.commonObj.officeTypeList
            return data.data.map(item => {
                const productionSeasonObject = productionSeasonList.find(productionSeason => productionSeason.value === item.production_season_id && productionSeason.status === 1 )
                const cropTypeListObject = cropTypeListList.find(cropTypeList => cropTypeList.value === item.crop_type_id && cropTypeList.status === 1 )
                const cropNameListObject = cropNameListList.find(cropNameList => cropNameList.value === item.crop_name_id && cropNameList.status === 1)
                const fiscalyearObject = fiscalyearData.find(fiscalyear => fiscalyear.value === item.fiscal_year_id && fiscalyear.status === 0)
                const seedClassObject = seedClassList.find(seedClass => seedClass.value === item.seed_class_id && seedClass.status === 1)
                const orgObject = organizationList.find(oganization => oganization.value === item.org_id && oganization.status === 0)
                const spcOfficeObject = spcOfficeList.find(spcOffice => spcOffice.value === item.from_spc_office_id && spcOffice.status === 1)
                const zoneOfficeObject = zoneOfficeList.find(zoneOffice => zoneOffice.value === item.to_sales_office_id)
                const carryOverObject = carryoverList.find(carryover => carryover.value === item.carryovers_id && carryover.status === 1)
                const measurementUnitObject = measurementUnitList.find(measurementUnit => measurementUnit.value === item.measurement_unit_id)
                const varietyObject = varietyList.find(listitem => listitem.value === item.details.variety_id && listitem.status === 1)
                const dealerObject = dealerList.find(dealernameList => dealernameList.value === item.off_dealer_farmer_comp_id && dealernameList.status === 1)
                const officeTypeObj = officeTypeList.find(officeType => officeType.value === item.office_type_id)
                const productionSeasonData = {
                    production_season_name: productionSeasonObject !== undefined ? productionSeasonObject.text_en : '',
                    production_season_name_bn: productionSeasonObject !== undefined ? productionSeasonObject.text_bn : ''
                }
                const cropTypeListData = {
                    crop_type_name: cropTypeListObject !== undefined ? cropTypeListObject.text_en : '',
                    crop_type_name_bn: cropTypeListObject !== undefined ? cropTypeListObject.text_bn : ''
                }
                const cropNameListData = {
                    crop_name_name: cropNameListObject !== undefined ? cropNameListObject.text_en : '',
                    crop_name_name_bn: cropNameListObject !== undefined ? cropNameListObject.text_bn : ''
                }
                const FiscalYearData = {
                    fiscal_year: fiscalyearObject !== undefined ? fiscalyearObject.text_en : '',
                    fiscal_year_bn: fiscalyearObject !== undefined ? fiscalyearObject.text_bn : ''
                }
                const seedClasseData = {
                    seed_class: seedClassObject !== undefined ? seedClassObject.text_en : '',
                    seed_class_bn: seedClassObject  !== undefined? seedClassObject.text_bn : ''
                }
                const orgData = {
                    org_name: orgObject !==undefined ? orgObject.text_en : '',
                    org_name_bn: orgObject !== undefined ? orgObject.text_bn : ''
                }
                const spcOfficeData = {
                    spcOffice_name: spcOfficeObject !== undefined ? spcOfficeObject.text_en : '',
                    spcOffice_name_bn: spcOfficeObject !== undefined ? spcOfficeObject.text_bn : ''
                }
                const zoneOfficeData = {
                    zoneOffice_name: zoneOfficeObject !== undefined ? zoneOfficeObject.text_en : '',
                    zoneOffice_name_bn: zoneOfficeObject !== undefined ? zoneOfficeObject.text_bn : ''
                }
                const carryOverData = {
                    carryOver_name: carryOverObject !== undefined ? carryOverObject.text_en : '',
                    carryOver_name_bn: carryOverObject !== undefined ? carryOverObject.text_bn : ''
                }
                const measurementUnitData = {
                    measurementUnit_name: measurementUnitObject !== undefined ? measurementUnitObject.text_en : '',
                    measurementUnit_name_bn: measurementUnitObject !== undefined ? measurementUnitObject.text_bn : ''
                }
                const  varietyData = {
                    variety_name: varietyObject !== undefined ? varietyObject.text_en : '',
                    variety_name_bn: varietyObject !== undefined ? varietyObject.text_bn : ''
                }
                const  dealerData = {
                    dealer_name: dealerObject !== undefined ? dealerObject.text_en : '',
                    dealer_name_bn: dealerObject !== undefined ? dealerObject.text_bn : ''
                }

                const officeTypeData = {
                    office_type: officeTypeObj ? officeTypeObj.text_en : '',
                    office_type_bn: officeTypeObj ? officeTypeObj.text_bn : ''
                }
                return Object.assign({}, item, productionSeasonData, cropTypeListData, cropNameListData, FiscalYearData, seedClasseData, orgData, spcOfficeData, zoneOfficeData, carryOverData, measurementUnitData, varietyData, dealerData, officeTypeData)
            })
        },
        pdfExport () {
            this.$refs.details.pdfExport()
        }
    } 
}
</script>
