import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, thisObject) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      {
          columns: reportHeadData.reportHeadColumn
      },
      { text: reportHeadData.address, style: 'address', alignment: 'center' }
    ]
    pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
    const allRowsHead = [
        [
        { text: thisObject.$t('org_pro.organization'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.org_name : data.org_name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('warehouse_config.fiscal_year'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.fiscal_year : data.fiscal_year_bn, alignment: 'left', style: 'search' }
      ]
    ]
    allRowsHead.push([
      { text: thisObject.$t('seedsConfig.seedClass'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.seed_class : data.seed_class_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('seedsConfig.measurementUnit'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.measurementUnit_name : data.measurementUnit_name_bn, alignment: 'left', style: 'search' }
    ])
    allRowsHead.push([
      { text: thisObject.$t('seedsConfig.cropType'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.crop_type_name : data.crop_name_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('seedsConfig.cropName'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.crop_name_name : data.crop_name_name_bn, alignment: 'left', style: 'search' }
    ])
    allRowsHead.push([
      { text: thisObject.$t('seedsSeeds.to_spcOfc'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.zoneOffice_name : data.zoneOffice_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('seedsConfig.productionSeason'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.production_season_name : data.production_season_name_bn, alignment: 'left', style: 'search' }
    ])
    allRowsHead.push([
      { text: thisObject.$t('seedsConfig.carryover'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.carryOver_name : data.carryOver_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('seedsSeeds.allocation_date'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: dateFormat(data.allocation_date), alignment: 'left', style: 'search' }
    ])
    allRowsHead.push([
      { text: thisObject.$t('seedsSeeds.dealer_name'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.dealer_name : data.dealer_name_bn, alignment: 'left', style: 'search' },
      { },
      { },
      { }
    ])
      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })
      pdfContent.push({ text: thisObject.$t('seedsSeeds.variety_list'), style: 'hh', alignment: 'center' })
      // option
        let allRows = []
        allRows = [
          [
            { text: thisObject.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: thisObject.$t('seedsConfig.variety'), style: 'th', alignment: 'center' },
            { text: thisObject.$t('globalTrans.quantity'), style: 'th', alignment: 'center' }
          ]
        ]
        // table body
        data.details.filter((data, optionIndex) => {
          const rowItem = [
            { text: thisObject.$n(optionIndex + 1), style: 'td', alignment: 'center' },
            { text: thisObject.$i18n.locale === 'bn' ? data.variety.variety_name_bn : data.variety.variety_name, style: 'td', alignment: 'center' },
            { text: thisObject.$n(data.quantity, { useGrouping: false }), style: 'td', alignment: 'center' }
          ]
          allRows.push(rowItem)
        })
        pdfContent.push({ text: '', style: 'tableMargin' })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: i18n.locale === 'bn' ? 14 : 12,
            margin: [0, 10, 0, 10]
          },
          header3: {
            fontSize: i18n.locale === 'bn' ? 13 : 11,
            margin: [0, 10, 0, 5]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
