<template>
  <b-container fluid>
      <b-row v-if="!loader">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                    <b-col>
                        <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="seedOrgId">
                            {{ $t('seedsSeeds.DealerWiseAllocationDetails') }}
                        </list-report-head>
                    </b-col>
                  </b-row>
                <b-row>
                  <b-col lg="12" sm="12">
                      <b-row>
                        <b-col lg="2" sm="12">
                          <p class="text-dark">{{ $t('org_pro.organization') }} : </p>
                        </b-col>
                        <b-col lg="7" sm="12">
                          <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.org_name_bn  :  data.org_name  }}</p>
                        </b-col>
                      </b-row>
                  </b-col>
                  <b-col lg="6" sm="12">
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{$t('warehouse_config.fiscal_year')}} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.fiscal_year_bn  :  data.fiscal_year  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('seedsConfig.seedClass') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ ($i18n.locale ==='bn') ? data.seed_class_bn  :  data.seed_class  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{$t('seedsConfig.measurementUnit')}} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.measurementUnit_name_bn  :  data.measurementUnit_name  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{$t('seedsConfig.cropType')}} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.crop_type_name_bn  :  data.crop_type_name  }}</p>
                      </b-col>
                    </b-row>
                     <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{$t('seedsConfig.cropName')}} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.crop_name_name_bn  :  data.crop_name_name  }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="6" sm="12">
                     <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{$t('seedsSeeds.to_spcOfc')}}: </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale==='bn') ? data.zoneOffice_name_bn  :  data.zoneOffice_name  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark capitalize">{{$t('seedsConfig.productionSeason')}} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ ($i18n.locale==='bn') ? data.production_season_name_bn  :  data.production_season_name  }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{$t('seedsConfig.carryover')}} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.carryOver_name_bn  :  data.carryOver_name  }}</p>
                      </b-col>
                    </b-row>
                     <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{$t('seedsSeeds.allocation_date')}} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{data.allocation_date | dateFormat}}</p>
                      </b-col>
                    </b-row>
                      <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{$t('seedsSeeds.dealer_name')}} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                            <p class="text-dark capitalize">{{ ($i18n.locale==='bn') ? data.dealer_name_bn  :  data.dealer_name  }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                  <table class="table table-bordered">
                  <tr>
                    <th style="width:15%">{{$t('globalTrans.sl_no')}}</th>
                    <th style="width:50% font-size:110%"  class="text-center">{{$t('seedsConfig.variety')}}</th>
                    <th class="text-center font-size:110%">{{$t('globalTrans.quantity')}}</th>
                  </tr>
                  <slot v-if="data.details.length > 0">
                    <tr :key="index" v-for="(res,index) in data.details">
                      <td>{{index+1}}</td>
                        <td class="text-center">
                        {{ ($i18n.locale==='bn') ? res.variety.variety_name_bn  :  res.variety.variety_name }}
                      </td>
                      <td class="text-center">
                        {{$n(res.quantity)}}
                      </td>
                    </tr>
                  </slot>
                  <slot v-else>
                    <tr><td colspan="5"><h4 class="text-danger text-center">{{$t('globalTrans.noDataFound')}}</h4></td></tr>
                  </slot>
                  </table>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import ExportPdf from './export_pdf_details'
import ListReportHead from '@/components/custom/ListReportHeadSeed.vue'
import { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList } from '../../api/routes'

export default {
  components: {
    ListReportHead
  },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getdealerInfoData()
       this.data = tmp
    }
    this.seedOrgId = 1
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.seedOrgId = this.$store.state.Auth.authUser.org_id
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.view'),
      seedOrgId: 0,
      data: {
        id: '',
        allocation_type: 1,
        org_id: 0,
        fiscal_year_id: 0,
        fiscal_year_bn: '',
        fiscal_year: '',
        production_season_id: 0,
        division_id: 0,
        district_id: 0,
        seed_class_id: 0,
        crop_type_id: 0,
        crop_name_id: 0,
        price_type_id: 0,
        carryovers_id: 0,
        from_spc_office_id: 0,
        to_sales_office_id: 0,
        measurement_unit_id: 0,
        allocation_date: '',
        variety_name: '',
        variety_name_bn: '',
        details: []
      },
      cropNameList: [],
      loader: false,
      search: {
        crop_name_id: 0
      },
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  methods: {
    getdealerInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getCropNameList (croptypeID) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (croptypeID) {
        return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID)
      }
      return cropNameList
    },
    pdfExport () {
      const reportTitle = this.$t('seedsSeeds.DealerWiseAllocationDetails')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/seeds/config/report-heading/detail', this.seedOrgId, reportTitle, this.data, this)
    }
  }
}
</script>
