<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Organization" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{ $t('org_pro.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                    plain
                                    v-model="data.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fiscal Year" vid="fiscal_year_id"  rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="data.fiscal_year_id"
                                  :options="fiscalList"
                                  id="fiscal_year_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Office Type" vid="office_type_id"  rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('seedsSeeds.office_type')}}<span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="data.office_type_id"
                                  :options="officeTypeList"
                                  id="office_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        <b-col lg="6"  sm="12">
                          <ValidationProvider name="Division" vid="pre_division_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="pre_division_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{$t('fertilizerConfig.name_of_division')}}<span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="data.pre_division_id"
                                  :options="divisionPreList"
                                  id="pre_division_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                      </b-col>
                        <b-col lg="6"  sm="12">
                            <ValidationProvider name="District" vid="pre_district_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="pre_district_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{$t('fertilizerConfig.name_of_district')}}<span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="data.pre_district_id"
                                    :options="districtList"
                                    id="pre_district_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        <b-col lg="6"  sm="12">
                              <ValidationProvider name="Upazilla" vid="pre_upazilla_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="pre_upazilla_id"
                                      slot-scope="{ valid, errors }"
                                      >
                                      <template v-slot:label>
                                      {{$t('fertilizerConfig.name_of_upazilla')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="data.pre_upazilla_id"
                                      :options="upazilaList"
                                      id="pre_upazilla_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                              <ValidationProvider name="Dealer Name" vid="off_dealer_farmer_comp_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="off_dealer_farmer_comp_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{ $t('seedsSeeds.dealer_name')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="data.off_dealer_farmer_comp_id"
                                      id="off_dealer_farmer_comp_id"
                                      :options="dealerList"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0" disable>{{ $t('seedsSeeds.all_dealer') }}-{{ $n(dealerNo) }}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                              <ValidationProvider name="Registration No" vid="registration" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="registration no"
                                      slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('seedsSeeds.regNo')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                      readonly
                                      id="registration_no"
                                      v-model="data.registration_no"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                        </b-col>
                        <b-col lg="6"  sm="12">
                              <ValidationProvider name="NID" vid="nid" rules="required">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="nid"
                                      slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('fertilizerConfig.nid')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                      readonly
                                      type="number"
                                      id="nid"
                                      v-model="data.nid"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="12">
                              <ValidationProvider name="Production Season" vid="production_season_id"  rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="production_season"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{$t('seedsConfig.productionSeason')}} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="data.production_season_id"
                                      :options="productionSeasonList"
                                      id="production_season_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                        </b-col>
                          <b-col lg="6" sm="12">
                              <ValidationProvider name="Sale/Zone Office" vid="to_sales_office_id"  rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="4"
                                      label-for="to_sales_office_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                      <template v-slot:label>
                                      {{$t('seedsSeeds.sale_office')}}<span class="text-danger">*</span>
                                      </template>
                                      <b-form-select
                                      plain
                                      v-model="data.to_sales_office_id"
                                      :options="zoneOfficeList"
                                      id="to_sales_office_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      >
                                      <template v-slot:first>
                                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                      </template>
                                      </b-form-select>
                                      <div class="invalid-feedback">
                                      {{ errors[0] }}
                                      </div>
                                  </b-form-group>
                              </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Carryover Id" vid="carryovers_id"  rules="required|min_value:1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="carryovers_id"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('seedsConfig.carryover')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                plain
                                v-model="data.carryovers_id"
                                :options="carryoverList"
                                id="carryovers_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                              </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                            <ValidationProvider name="Seed Class" vid="seed_class"  rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="seed_class"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('seedsConfig.seedClass')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="data.seed_class_id"
                                    :options="seedClassList"
                                    id="seed_class_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Crop Type" vid="crop_type"  rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="crop_type"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('seedsConfig.cropType')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="data.crop_type_id"
                                  :options="cropTypeList"
                                  id="crop_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Crop Name" vid="crop_name_id"  rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="crop_name_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('seedsConfig.cropName')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="data.crop_name_id"
                                  :options="cropNameList"
                                  id="crop_name_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @change="getVarietyInfo(data.crop_name_id)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Measurement Unit ID" vid="measurement_unit_id"  rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="measurement_unit_id"
                                  slot-scope="{ valid, errors }"
                              >
                                  <template v-slot:label>
                                  {{$t('seedsConfig.measurementUnit')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="data.measurement_unit_id"
                                  :options="measurementUnitList"
                                  id="measurement_unit_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Allocation Date" vid="allocation_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="allocation_date"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('seedsSeeds.allocation_date')}}<span class="text-danger">*</span>
                              </template>
                              <b-form-input class="form-control"
                                v-model="data.allocation_date"
                                placeholder="Select Date"
                                id="date"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                        <div class="col-12"></div>
                        <div class="d-flex justify-content-center" v-if="loader" style="margin: auto;">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        <table class="table table-bordered" v-else>
                          <tr>
                            <th>{{$t('globalTrans.sl_no')}}</th>
                            <th>{{$t('seedsConfig.variety')}}</th>
                            <th>{{$t('globalTrans.quantity')}}</th>
                            <!-- <th>{{$t('seedsSeeds.unitPrice')}}</th>
                            <th>{{$t('seedsSeeds.totalPrice')}}</th> -->
                          </tr>
                          <slot v-if="data.details.length>0">
                            <tr :key="index" v-for="(res,index) in data.details">
                              <td>{{index+1}}</td>
                              <td>
                                <slot v-if="res.variety">
                                  {{ ($i18n.locale==='bn') ? res.variety.variety_name_bn  :  res.variety.variety_name }}
                                </slot>
                                <slot v-else>
                                  {{ ($i18n.locale==='bn') ? res.variety_name_bn  :  res.variety_name }}
                                </slot>
                              </td>
                              <td>
                                <b-form-input
                                  v-model="res.quantity"
                                  type="number"
                                  class="mb-2 mr-sm-2 mb-sm-0 form-control"
                                ></b-form-input>
                              </td>
                              <!-- <td>
                                <b-form-input
                                  v-model="res.unit_price"
                                  type="number"
                                  min="0"
                                  @input="QtyPriceAdd(res)"
                                ></b-form-input>
                              </td> -->
                              <!-- <td>
                                <b-form-input
                                  v-model="res.total_price"
                                  type="number"
                                  class="mb-2 mr-sm-2 mb-sm-0 form-control"
                                  min="0"
                                  readonly
                                ></b-form-input>
                              </td> -->
                            </tr>
                          </slot>
                          <slot v-else>
                            <tr><td colspan="5"><h4 class="text-danger text-center">{{$t('globalTrans.noDataFound')}}</h4></td></tr>
                          </slot>
                        </table>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { dealerWiseAllocationStore, varietiesPriceWise, officeWiseAllocationEdit, nameWiseRegistrationList } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    this.data.org_id = this.$store.state.dataFilters.orgId
    this.data = Object.assign({}, this.data, { fiscal_year_id: this.currentFiscalYearId })
    if (this.id) {
      this.getEditData(this.id)
    }
  },
  mounted () {
    flatpickr('#date', {})
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      data: {
        id: '',
        allocation_type: 3,
        org_id: 0,
        fiscal_year_id: 0,
        production_season_id: 0,
        division_id: 0,
        district_id: 0,
        seed_class_id: 0,
        crop_type_id: 0,
        crop_name_id: 0,
        price_type_id: 0,
        carryovers_id: 0,
        office_type_id: 0,
        to_sales_office_id: this.$store.state.dataFilters.officeId,
        off_dealer_farmer_comp_id: 0,
        measurement_unit_id: 2,
        name: '',
        nid: '',
        registration_no: '',
        allocation_date: null,
        pre_division_id: 0,
        pre_district_id: 0,
        pre_upazilla_id: 0,
        details: []
      },
      cropNameList: [],
      districtList: [],
      upazilaList: [],
      dealerList: [],
      dealerInfoList: [],
      zoneOfficeList: [],
      spcOfficeList: [],
      spcOfficeStocks: [],
      officeTypeList: [],
      varieties: [],
      newData: [],
      loader: false,
      loading: false,
      search: {
        crop_name_id: 0
      },
      dealerNo: 0
    }
  },
   watch: {
    'data.crop_type_id': function (newVal, oldVal) {
      this.cropNameList = this.getCropNameList(newVal)
    },
    'data.off_dealer_farmer_comp_id': function (newVal, oldVal) {
      this.getDealerInfoList(newVal)
    },
    'data.pre_division_id': function (newVal, oldVal) {
      this.districtList = this.getPreDistrictList(newVal)
    },
    'data.pre_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getPreUpazilaList(newVal)
      // this.zoneOfficeList = this.getZoneOfficeList(newVal)
    },
    'data.pre_upazilla_id': function (newVal, oldVal) {
      this.dealerList = this.getDealerList(newVal)
    },
    'data.org_id': function (newVal, oldVal) {
      this.officeTypeList = this.getOfficeTypeList(newVal)
      this.data.office_type_id = this.$store.state.dataFilters.officeTypeId
    },
    'data.office_type_id': function (newVal, oldVal) {
     this.zoneOfficeList = this.getZoneOfficeList(newVal)
    }
  },
  computed: {
     divisionPreList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    orgList: function () {
      const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      return tmpData.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text }
        }
      })
    },
    fiscalList: function () {
        const fiscalyearData = this.$store.state.commonObj.fiscalYearList
        return fiscalyearData.map(item => {
            if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
            } else {
            return { value: item.value, text: item.text_en }
            }
        })
    },
    productionSeasonList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
    },
    seedClassList: function () {
       return this.$store.state.SeedsFertilizer.commonObj.seedClassList.filter(item => item.status === 1)
    },
    cropTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
    },
    carryoverList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.carryoverList.filter(item => item.status === 1)
    },
    measurementUnitList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.unitList
    },
    currentFiscalYearId: function () {
      return this.$store.state.SeedsFertilizer.currentFiscalYearId
    }
  },
  methods: {
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, dealerWiseAllocationStore, this.data)
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
      this.loading = false
    },
    // QtyPriceAdd (item) {
    //   item.total_price = item.quantity * item.unit_price
    // },
    getPreDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getPreUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getDealerList (upazillaId = null) {
      let dealerList = this.$store.state.SeedsFertilizer.commonObj.dealerBasicList
      if (upazillaId) {
        dealerList = dealerList.filter(dealer => dealer.pre_upazilla_id === parseInt(upazillaId))
      }
      this.dealerNo = dealerList.length
      return dealerList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn + '-' + item.dealer_code }
        } else {
          return { value: item.value, text: item.text_en + '-' + item.dealer_code }
        }
      })
    },
    getSpcOfficeList (divisionId = null) {
      const spcOfficeList = this.$store.state.SeedsFertilizer.commonObj.spcOfficeList.filter(item => item.status === 1)
      if (divisionId) {
        return spcOfficeList.filter(spcOffice => spcOffice.division_id === divisionId)
      }
      return spcOfficeList
    },
    getZoneOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getEditData (id) {
      RestApi.getData(seedFertilizerServiceBaseUrl, officeWiseAllocationEdit + '/' + id, {}).then(response => {
        this.data = response.data
        this.geSpcOfficeStocksInfo(this.data.from_spc_office_id)
      })
    },
    getCropNameList (croptypeID) {
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1)
      if (croptypeID) {
        return cropNameList.filter(cropName => cropName.crop_type_id === croptypeID)
      }
      return cropNameList
    },
    getVarietyInfo (cropNameId) {
      this.loader = true
      this.search.org_id = this.data.org_id
      this.search.allocation_type = this.data.allocation_type
      this.search.fiscal_year_id = this.data.fiscal_year_id
      this.search.production_season_id = this.data.production_season_id
      this.search.crop_type_id = this.data.crop_type_id
      this.search.crop_name_id = this.data.crop_name_id
      this.search.seed_class_id = this.data.seed_class_id
      this.search.off_dealer_farmer_comp_id = this.data.off_dealer_farmer_comp_id
      this.search.to_sales_office_id = this.data.to_sales_office_id
      this.search.carryovers_id = this.data.carryovers_id
      RestApi.getData(seedFertilizerServiceBaseUrl, varietiesPriceWise, this.search).then(response => {
        if (response.data) {
          this.data.details = response.data.map(item => {
            const quantityData = {
                unit_price: item.price,
                total_price: 0
            }
            return Object.assign({}, item, quantityData)
          })
        } else {
          this.data.details = {}
        }
        this.loader = false
      })
    },
    maxQtyCheck (item) {
      if (item.quantity > item.max) {
        item.quantity = item.max
        this.$swal({
          title: this.$t('allocationProgram.qty_more_than_stock'),
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          focusConfirm: false
        }).then((resultSwal) => {
        })
      }
    },
    getDealerInfoList (Id = null) {
        RestApi.getData(seedFertilizerServiceBaseUrl, nameWiseRegistrationList + '/' + Id)
          .then(response => {
            if (response.data) {
              this.data.registration_no = response.data[0].registration_no
              this.data.nid = response.data[0].nid
            }
       })
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
            return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text }
                }
            })
        }
      return officeTypeList
    }
  }
}
</script>
